.product {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	padding: 2.5rem 1.5rem 4.5rem;
	background: colors.brand(gray, 50);
	overflow: hidden;

	@media (min-width: bps.$L) {
		padding: 5rem 2.5rem 5.5rem;
	}

	@media (min-width: bps.$XL) {
		padding: 5rem 7.5rem 5.5rem;
	}

	.container {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		max-width: 80em;
		width: 100%;
	}

	.bg-triangle {
		position: absolute;
		width: 10rem;
		height: 11;
		top: 15rem;
		right: 0rem;

		@media (min-width: bps.$L) {
			width: 22.625rem;
			height: 26.313rem;
			top: 31.813rem;
			right: 0rem;
		}
	}

	.bg-pic {
		position: absolute;
		width: 10rem;
		height: 11rem;
		left: 0rem;
		top: 45rem;

		@media (min-width: bps.$L) {
			width: 15.563rem;
			height: 15.063rem;
			left: 0rem;
			top: 36.75rem;
		}
	}

	.titles {
		margin-bottom: 3rem;

		@media (min-width: bps.$L) {
			margin-bottom: 4.5rem;
			padding-left: 9.6rem;
			padding-right: 9.6rem;
		}

		h1 {
			margin-bottom: 1.5rem;
		}
		p {
			text-align: center;
			color: colors.brand(gray, 600);

			@media (min-width: bps.$L) {
				font-size: 1.5rem;
			}
		}
	}

	.cards {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		gap: 1.5rem;
		z-index: 1;
		margin-bottom: 3rem;
		width: 100%;

		@media (min-width: bps.$L) {
			margin-bottom: 4.5rem;
			flex-direction: row;
		}

		.card {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			flex: 0 1 auto;
			width: 100%;
			padding: 2.5rem 2rem;
			background: colors.brand(white, 0);

			h4 {
				margin-bottom: 1.5rem;
			}

			img {
				height: 6.5rem;
				align-self: flex-start;
				margin-bottom: 1.5rem;
			}

			p {
				text-align: start;
				font-size: 16px;
				color: colors.brand(gray, 600);
			}
		}
	}
}

.extensibility {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 4.5rem 1.5rem 1.5rem;

	@media (min-width: bps.$L) {
		padding: 7.5rem 2.5rem;
	}

	@media (min-width: bps.$XL) {
		padding: 7.5rem;
	}

	.container {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		max-width: 80em;
		width: 100%;
	}

	.caption-title {
		display: flex;
		flex-direction: column;
		align-content: space-between;
		width: 100%;
		gap: 1.5rem;
		margin-bottom: 3rem;

		@media (min-width: bps.$L) {
			flex-direction: row;
			margin-bottom: 0;
		}

		h2 {
			width: 100%;

			@media (min-width: bps.$L) {
				margin-bottom: 4.5rem;
			}
		}

		p {
			width: 100%;
			color: colors.brand(gray, 600);
		}
	}
}

.industry {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	padding: 4.5rem 1.5rem;
	background: colors.brand(gray, 900);

	@media (min-width: bps.$L) {
		padding: 7.5rem 2.5rem;
	}

	@media (min-width: bps.$XL) {
		padding: 7.5rem;
	}

	.container {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		max-width: 80em;
		width: 100%;
	}

	.caption-title {
		display: flex;
		flex-direction: column;
		margin-bottom: 3rem;
		gap: 1.5rem;

		@media (min-width: bps.$L) {
			flex-direction: row;
			margin-bottom: 5.5rem;
		}

		h2 {
			width: 100%;
			color: colors.brand(white, 0);
		}

		p {
			width: 100%;
			color: colors.brand(gray, 300);
			vertical-align: bottom;
		}
	}

	.image-holder {
		display: flex;
		flex-direction: row;
		flex-direction: column;
		align-items: stretch;
		width: 100%;

		@media (min-width: bps.$L) {
			flex-direction: row;
		}

		.links {
			width: 100%;

			ul {
				padding: 0;
				list-style-type: none;
				gap: 0.5rem;
				position: relative;

				li {
					width: 100%;
					margin-bottom: 0.25rem;
					scroll-margin-top: 4.5rem;

					@media (min-width: bps.$L) {
						margin-bottom: 0;
					}

					a {
						font-weight: 500;
						font-size: 1.25rem;
						text-decoration: none;
						letter-spacing: 0.0075em;
						color: colors.brand(gray, 400);
						padding: 1rem 1.5rem;
						display: block;
						background: colors.brand(gray, 800);

						@media (min-width: bps.$L) {
							display: inline-block;
							width: 50%;
							background: none;
						}

						&:hover {
							background: colors.brand(gray, 800);
						}
					}

					&.selected {
						a {
							background: colors.brand(gray, 700);

							@media (min-width: bps.$L) {
								background: colors.brand(gray, 800);
							}
						}

						h5 {
							color: colors.brand(white, 0);
						}

						figure {
							display: block;
							background: colors.brand(gray, 700);

							@media (min-width: bps.$L) {
								position: absolute;
								right: 0;
								top: 0;
								width: 50%;
								height: 100%;
								background: colors.brand(gray, 800);
							}
						}
					}

					figure {
						padding: 1.5rem;
						scroll-margin-top: 4rem;
						display: none;
						background: colors.brand(gray, 800);

						img {
							margin-bottom: 1rem;
							width: 100%;
							height: auto;
						}

						figcaption {
							color: colors.brand(gray, 300);
						}
					}
				}
			}
		}
	}
}
.product-process {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	padding: 4.5rem 1.5rem;
	background: colors.brand(white, 0);

	@media (min-width: bps.$L) {
		padding: 7.5rem 2.5rem;
	}

	@media (min-width: bps.$XL) {
		padding: 7.5rem;
	}

	.container {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		max-width: 80em;
		width: 100%;
	}

	.caption-title {
		display: flex;
		flex-direction: column;
		gap: 1.5rem;
		width: 100%;

		@media (min-width: bps.$L) {
			flex-direction: row;
		}

		h2 {
			width: 100%;
		}

		.caption-link {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			row-gap: 1.5rem;
			width: 100%;

			@media (min-width: bps.$L) {
				padding-top: 0.75rem;
			}

			p {
				color: colors.brand(gray, 600);
			}
		}
	}
	.button {
		text-align: center;

		@media (min-width: bps.$L) {
			margin-top: 2.5rem;
		}
	}
}

.support {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 4.5rem 1.5rem;
	background: colors.brand(gray, 50);

	@media (min-width: bps.$L) {
		padding: 7.5rem 2.5rem;
	}

	@media (min-width: bps.$XL) {
		padding: 7.5rem;
	}

	.container {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		max-width: 80em;
		width: 100%;
	}

	.titles {
		display: flex;
		flex-direction: column;
		gap: 1.5rem;
		margin-bottom: 3rem;

		@media (min-width: bps.$L) {
			flex-direction: row;
			margin-bottom: 5.5rem;
		}

		h2 {
			width: 100%;
		}

		p {
			width: 100%;
			color: colors.brand(gray, 600);

			@media (min-width: bps.$L) {
				margin-top: 0.75rem;
			}
		}
	}

	.cards {
		display: flex;
		flex-direction: column;
		gap: 1.5rem;
		z-index: 1;
		width: 100%;

		@media (min-width: bps.$L) {
			flex-direction: row;
		}

		.card {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			flex: 0 1 auto;
			width: 100%;
			padding: 2.5rem 2rem;
			row-gap: 1.5rem;
			background: colors.brand(white, 0);

			p {
				text-align: start;
				font-size: 16px;
				color: colors.brand(gray, 600);
				flex-grow: 1;
			}
		}
	}
}
.used {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 4.5rem 1.5rem;

	@media (min-width: bps.$L) {
		padding: 7.5rem 2.5rem;
	}

	@media (min-width: bps.$XL) {
		padding: 7.5rem;
	}

	.container {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		max-width: 80em;
		width: 100%;
	}
	h2 {
		margin-bottom: 2.5rem;

		@media (min-width: bps.$L) {
			margin-bottom: 4.5rem;
		}
	}
	.image-container {
		display: flex;
		flex-direction: column;
		width: 100%;

		.logos {
			display: flex;
			flex-direction: row;
			column-gap: 1rem;

			a {
				padding: 0.75rem;

				@media (min-width: bps.$L) {
					padding: 1.5rem;
				}

				img {
					height: 2.5rem;
				}

				&.active {
					background: colors.brand(gray, 50);
				}

				&:hover {
					background: colors.brand(gray, 50);
				}
			}
		}

		.images {
			.image {
				position: relative;
				width: 100%;
				display: none;
				padding: 0;
				justify-content: flex-end;
				background-size: cover;
				background-repeat: no-repeat;

				&.active {
					display: flex;
				}

				@media (min-width: bps.$L) {
					padding: 4.5rem 0 4.5rem 4.5rem;
				}

				article {
					max-width: 43rem;
					padding: 2.5rem 1.5rem;
					background-color: colors.brand(gray, 50);
					display: flex;
					flex-direction: column;
					align-items: flex-start;

					@media (min-width: bps.$L) {
						padding: 4.5rem;
					}

					h3 {
						font-size: 1.5rem;
						margin-top: 0;
						margin-bottom: 1.5rem;
					}

					p {
						font-size: 16px;
						color: colors.brand(gray, 600);
						margin-bottom: 1.5rem;

						@media (min-width: bps.$L) {
							margin-bottom: 2.5rem;
						}
					}

					div {
						color: colors.brand(gray, 900);
						margin-bottom: 0.5rem;
					}
				}
			}
		}
	}
}
