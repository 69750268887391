@font-face {
	font-family: "Archivo";
	src: url("../font/Archivo-Regular.woff2") format("woff2"),
		url("../font/Archivo-Regular.woff") format("woff");
	font-weight: 400;
	font-display: swap;
}

@font-face {
	font-family: "Archivo";
	src: url("../font/Archivo-Thin.woff2") format("woff2"),
		url("../font/Archivo-Thin.woff") format("woff");
	font-weight: 100;
	font-display: swap;
}

@font-face {
	font-family: "Archivo";
	src: url("../font/Archivo-SemiBold.woff2") format("woff2"),
		url("../font/Archivo-SemiBold.woff") format("woff");
	font-weight: 600;
	font-display: swap;
}

@font-face {
	font-family: "DM Sans";
	src: url("../font/DMSans-Regular.woff2") format("woff2"),
		url("../font/DMSans-Regular.woff") format("woff");
	font-weight: 400;
	font-display: swap;
}

@font-face {
	font-family: "DM Sans";
	src: url("../font/DMSans-Medium.woff2") format("woff2"),
		url("../font/DMSans-Medium.woff") format("woff");
	font-weight: 500;
	font-display: swap;
}

$archivo-stack: "Archivo", Arial, system-ui, -apple-system, BlinkMacSystemFont,
	"Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
	sans-serif;
$dm-sans-stack: "DM Sans", Arial, system-ui, -apple-system, BlinkMacSystemFont,
	"Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
	sans-serif;

html {
	font-family: $dm-sans-stack;
	line-height: 1.5;
	color: colors.brand(gray, 900);
}

/* page headers */
h1,
h2,
h3,
h4,
h5 {
	font-family: $archivo-stack;
}

h1 {
	font-size: 2.25rem;
	line-height: 120%;
	letter-spacing: -0.02em;

	@media (min-width: bps.$L) {
		font-size: 4rem;
	}
}

/* subheaders */
h2 {
	font-size: 2rem;
	line-height: 120%;
	letter-spacing: 0.005em;

	@media (min-width: bps.$L) {
		font-size: 3.5rem;
	}
}

h3 {
	font-size: 1.5rem;
	line-height: 120%;

	@media (min-width: bps.$L) {
		font-size: 2.5rem;
	}
}

h4 {
	font-weight: 600;
	font-size: 1.25rem;
	line-height: 2rem;

	@media (min-width: bps.$L) {
		font-size: 1.5rem;
	}
}

h5 {
	line-height: 125%;
	letter-spacing: 0.025em;

	@media (min-width: bps.$L) {
		font-size: 1.25rem;
	}
}
