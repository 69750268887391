$gutter: 1.25rem;
$main-column-width: 75rem;
$radius: .5rem;

$title-font-size: 2rem;
$title-font-size-l: 2.75rem;

$button-font-size: 1rem;
$button-font-size-l: 1.125rem;

$l-header-with-overlay-height: 3.5rem;

$bullet-list-max-width: calc(2.5rem + 35em);
