.interested {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	background: colors.brand(gray, 900);
	padding: 4.5rem 1.5rem 1.5rem 1.5rem;

	@media (min-width: bps.$L) {
		padding: 7.5rem 2.5rem 3rem 2.5rem;
	}

	@media (min-width: bps.$XL) {
		padding: 7.5rem 7.5rem 3rem 7.5rem;
	}

	.inner {
		display: flex;
		flex-direction: column;
		align-items: center;
		align-self: center;
		max-width: 80em;
		background: colors.brand(gradients, secondary-dark);
		padding: 2.5rem 1.5rem;
		width: 100%;

		@media (min-width: bps.$L) {
			padding: 7.5rem;
		}

		h1 {
			display: flex;
			align-items: center;
			color: colors.brand(white, 0);
			margin-bottom: 1.5rem;
		}

		p {
			font-family: $archivo-stack;
			display: flex;
			align-items: center;
			text-align: center;
			letter-spacing: 0.0015em;
			color: colors.brand(white, 0);
			margin-bottom: 2.5rem;

			@media (min-width: bps.$L) {
				margin-bottom: 3rem;
				font-size: 1.5rem;
			}
		}
	}
}
