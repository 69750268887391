.grid {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 1.5rem;

	.row {
		display: flex;
		flex-direction: column;
		gap: 1.5rem;

		@media (min-width: bps.$L) {
			flex-direction: row;
			gap: 3rem;
		}
	}

	.column {
		display: flex;
		flex-direction: column;
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;
		width: 100%;
		border-top: 4px solid colors.brand(gray, 200);
		color: colors.brand(gray, 600);

		@media (min-width: bps.$L) {
			padding-top: 2.5rem;
			padding-bottom: 2.5rem;
		}

		h4 {
			color: colors.brand(gray, 900);
		}

		strong {
			color: colors.brand(gray, 900);
		}
	}

	.column-spacer {
		width: 100%;
	}

	.grid-icon {
		width: 6.5rem;
		height: auto;
		margin-bottom: 1.5rem;
		margin-top: 1.5rem;
	}
}
